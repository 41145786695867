import React, { useState } from "react";
import axios from "axios";

export default function Contact() {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");

  const validateEmail = (email) => {
    return email.match(/\S+@\S+\.\S+/);
  };

  const handleOnChangeName = (event) => {
    setName(event.target.value);
    if (event.target.value.length >= 1) {
      document.getElementById("nameErrorInner").innerHTML = "";
      document
        .getElementById("formName")
        .classList.remove("border", "border-danger");
    }
  };
  const handleOnChangePhone = (event) => {
    setPhone(event.target.value);
    if (event.target.value.length >= 1) {
      document.getElementById("phoneErrorInner").innerHTML = "";
      document
        .getElementById("formPhone")
        .classList.remove("border", "border-danger");
    }
  };

  const handleOnChangeEmail = (event) => {
    setEmail(event.target.value);
    if (event.target.value.length >= 1) {
      document.getElementById("emailErrorInner").innerHTML = "";
      document
        .getElementById("formEmail")
        .classList.remove("border", "border-danger");
    }
  };
  const handleOnChangeSubject = (event) => {
    setSubject(event.target.value);
    if (event.target.value.length >= 1) {
      document.getElementById("subjectErrorInner").innerHTML = "";
      document
        .getElementById("formSubject")
        .classList.remove("border", "border-danger");
    }
  };
  const handleOnChangeMessage = (event) => {
    setMessage(event.target.value);
    if (event.target.value.length >= 1) {
      document.getElementById("messageErrorInner").innerHTML = "";
      document
        .getElementById("formMessage")
        .classList.remove("border", "border-danger");
    }
  };
  const contactFormSubmit = () => {
    let Name = name;
    let Email = email;
    let Phone = phone;
    let Subject = subject;
    let Message = message;

    if (
      Name === "" &&
      Name.length === 0 &&
      Email === "" &&
      Email.length === 0 &&
      Phone.length === 0 &&
      Subject === "" &&
      Subject.length === 0 &&
      Message === "" &&
      Message.length === 0
    ) {
      document
        .getElementById("formName")
        .classList.add("border", "border-danger");
      document.getElementById("formName").focus();

      document.getElementById("nameError").innerHTML =
        '<span class="text-danger errorClass" id="nameErrorInner"><i class="fa-solid fa-circle-exclamation"></i> Please enter name</span>';
      document
        .getElementById("formEmail")
        .classList.add("border", "border-danger");
      document.getElementById("emailError").innerHTML =
        '<span class="text-danger errorClass" id="emailErrorInner"><i class="fa-solid fa-circle-exclamation"></i> Please enter email address</span>';
      document
        .getElementById("formPhone")
        .classList.add("border", "border-danger");
      document.getElementById("phoneError").innerHTML =
        '<span class="text-danger errorClass" id="phoneErrorInner"><i class="fa-solid fa-circle-exclamation"></i> Please enter phone number</span>';
      document
        .getElementById("formSubject")
        .classList.add("border", "border-danger");
      document.getElementById("subjectError").innerHTML =
        '<span class="text-danger errorClass" id="subjectErrorInner"><i class="fa-solid fa-circle-exclamation"></i> Please enter subject</span>';
      document
        .getElementById("formMessage")
        .classList.add("border", "border-danger");
      document.getElementById("messageError").innerHTML =
        '<span class="text-danger errorClass" id="messageErrorInner"><i class="fa-solid fa-circle-exclamation"></i> Please write message</span>';
      return false;
    }

    if (Name === "" && Name.length === 0) {
      document
        .getElementById("formName")
        .classList.add("border", "border-danger");
      document.getElementById("formName").focus();
      document.getElementById("nameError").innerHTML =
        '<span class="text-danger errorClass" id="nameErrorInner"><i class="fa-solid fa-circle-exclamation"></i> Please enter name</span>';
      return false;
    }
    if (Email === "" && Email.length === 0) {
      document
        .getElementById("formEmail")
        .classList.add("border", "border-danger");
      document.getElementById("formEmail").focus();
      document.getElementById("emailError").innerHTML =
        '<span class="text-danger errorClass" id="emailErrorInner"><i class="fa-solid fa-circle-exclamation"></i> Please enter email address</span>';
      return false;
    }
    if (!validateEmail(Email)) {
      document
        .getElementById("formEmail")
        .classList.add("border", "border-danger");
      document.getElementById("formEmail").focus();
      document.getElementById("emailError").innerHTML =
        '<span class="text-danger errorClass" id="emailErrorInner"><i class="fa-solid fa-circle-exclamation"></i> Please enter valid email address</span>';
      return false;
    }
    if (Phone === "" && Phone.length === 0) {
      document
        .getElementById("formPhone")
        .classList.add("border", "border-danger");
      document.getElementById("formPhone").focus();
      document.getElementById("phoneError").innerHTML =
        '<span class="text-danger errorClass" id="phoneErrorInner"><i class="fa-solid fa-circle-exclamation"></i> Please enter phone number</span>';
      return false;
    }
    if (Subject === "" && Subject.length === 0) {
      document
        .getElementById("formSubject")
        .classList.add("border", "border-danger");
      document.getElementById("formSubject").focus();
      document.getElementById("subjectError").innerHTML =
        '<span class="text-danger errorClass" id="subjectErrorInner"><i class="fa-solid fa-circle-exclamation"></i> Please enter subject</span>';
      return false;
    }
    if (Message === "" && Message.length === 0) {
      document
        .getElementById("formMessage")
        .classList.add("border", "border-danger");
      document.getElementById("formMessage").focus();
      document.getElementById("messageError").innerHTML =
        '<span class="text-danger errorClass" id="messageErrorInner"><i class="fa-solid fa-circle-exclamation"></i> Please write message</span>';
      return false;
    }

    if (
      Name.length > 0 &&
      Email.length > 0 &&
      Phone.length > 0 &&
      Subject.length > 0 &&
      Message.length > 0 &&
      validateEmail(Email)
    ) {
      document.getElementById("formButton").disabled = true;
    }

    axios({
      method: "GET",
      url:
        "https://mannysahu.com/ReactAPI/contact_form_submit.php?name=" +
        Name +
        "&action=formAction&email=" +
        Email +
        "&phone=" +
        Phone +
        "&subject=" +
        Subject +
        "&message=" +
        Message,
      data: {
        name: Name,
        email: Email,
        phone: Phone,
        subject: Subject,
        message: Message,
        action: "formAction",
      },
      config: {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
        },
      },
    })
      .then(function (response) {
        setName("");
        setEmail("");
        setPhone("");
        setSubject("");
        setMessage("");
        var div = document.getElementById("formMessageResponse");
        div.innerHTML =
          ' <div class="my-3"> <div class="alert alert-success alert-dismissible fade show" role="alert"> Thank you for your message. It has been sent. </div> </div>';
        setTimeout(() => {
          document.getElementById("formMessageResponse").remove();
        }, 2500);
      })
      .catch(function (response) {
        var div = document.getElementById("formMessageResponse");
        div.innerHTML =
          ' <div class="my-3"> <div class="alert alert-danger" role="alert"> Server error! Call us: +91 7737826541 </div> </div>';
        setTimeout(() => {
          document.getElementById("formMessageResponse").remove();
        }, 5000);
      });
  };

  return (
    <>
      <main id="main" className="pt-5 mt-5">
        <section id="contact" className="contact">
          <div className="container" data-aos="fade-up">
            <div className="section-title">
              <h2>Contact</h2>
            </div>

            <div>
              <iframe
                style={{ border: "0", width: "100%", height: "270px" }}
                src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d14347.494265679597!2d76.29162!3d25.9721866!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xa237f400aca3f7a!2sManny%20Sahu-Jaipur&#39;s%20Best%20Web%20Developer%20for%20PHP%2C%20WordPress%2C%20CodeIgniter%20and%20Cake%20PHP!5e0!3m2!1sen!2sin!4v1645263492555!5m2!1sen!2sin"
                frameBorder="0"
                allowFullScreen
                title="Conatct us"
              ></iframe>
            </div>

            <div className="row mt-5">
              <div className="col-lg-4">
                <div className="info">
                  <div className="address">
                    <i className="bi bi-geo-alt"></i>
                    <h4>Location:</h4>
                    <p>Jaipur, Rajasthan &</p>
                    <p>Sawai Madhopur, Rajasthan</p>
                  </div>

                  <div className="email">
                    <i className="bi bi-envelope"></i>
                    <h4>Email:</h4>
                    <p>
                      <a href="mailto:manny.sahu@gmail.com">
                        manny.sahu@gmail.com
                      </a>
                    </p>
                  </div>

                  <div className="phone">
                    <i className="bi bi-phone"></i>
                    <h4>Call:</h4>
                    <p>
                      <a href="tel:+917737826541">+91 7737826541</a>
                    </p>
                  </div>
                </div>
              </div>

              <div className="col-lg-8 mt-5 mt-lg-0">
                <div className="row">
                  <div className="col-md-6 form-group input-effect">
                    <input
                      type="text"
                      name="name"
                      className="form-control labelEffect"
                      id="formName"
                      placeholder=""
                      value={name}
                      required
                      onChange={handleOnChangeName}
                    />
                    <label htmlFor="formName">Full Name</label>
                    <div id="nameError"></div>
                  </div>
                  <div className="col-md-6 form-group mt-3 mt-md-0 input-effect">
                    <input
                      type="email"
                      className="form-control labelEffect"
                      name="email"
                      id="formEmail"
                      placeholder=""
                      value={email}
                      required
                      onChange={handleOnChangeEmail}
                    />
                    <label htmlFor="formEmail">Email Address</label>
                    <div id="emailError"></div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6 form-group mt-3 input-effect">
                    <input
                      type="text"
                      name="phone"
                      className="form-control labelEffect"
                      id="formPhone"
                      placeholder=""
                      value={phone}
                      required
                      onChange={handleOnChangePhone}
                    />
                    <label htmlFor="formPhone">Phone Number</label>
                    <div id="phoneError"></div>
                  </div>
                  <div className="col-md-6 form-group mt-3 input-effect">
                    <input
                      type="text"
                      className="form-control labelEffect"
                      name="subject"
                      id="formSubject"
                      placeholder=""
                      value={subject}
                      required
                      onChange={handleOnChangeSubject}
                    />
                    <label htmlFor="formSubject">Subject</label>
                    <div id="subjectError"></div>
                  </div>
                </div>
                <div className="form-group mt-3 input-effect">
                  <textarea
                    className="form-control labelEffect"
                    name="message"
                    id="formMessage"
                    rows="5"
                    placeholder=""
                    value={message}
                    required
                    onChange={handleOnChangeMessage}
                  ></textarea>
                  <label htmlFor="formMessage">Message</label>
                  <div id="messageError"></div>
                </div>
                <div id="formMessageResponse"></div>

                <div className="text-center">
                  <button
                    id="formButton"
                    className="btn btn-about"
                    onClick={contactFormSubmit}
                  >
                    Send Message
                  </button>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </>
  );
}
