import React from "react";

export default function Resume() {
  return (
    <>
      <main id="main" className="pt-5 mt-5">
        <section id="resume" className="resume">
          <div className="container" data-aos="fade-up">
            <div className="section-title">
              <h2>Resume</h2>
            </div>

            <div className="row">
              <div className="col-lg-6">
                <h3 className="resume-title">Summary</h3>
                <div className="resume-item pb-0">
                  <h4>Manny Sahu</h4>
                  <p>
                    <em>
                      Since beginning my journey as a freelance designer nearly
                      7 years ago, I’ve done remote work for agencies, consulted
                      for startups, and collaborated with talented people to
                      create digital products for both business and consumer
                      use. I’m quietly confident, naturally curious, and
                      perpetually working on improving my chops one design
                      problem at a time.
                    </em>
                  </p>
                  <ul>
                    <li>Jaipur, Rajasthan, India</li>
                    <li>(+91) 7737826541</li>
                    <li>manny.sahu@gmail.com</li>
                  </ul>
                </div>

                <h3 className="resume-title">Education</h3>
                <div className="resume-item">
                  <h4>Master of Computer Science &amp; Engineering</h4>
                  <h5>2013 - 2015</h5>
                  <p>
                    <em>Rajasthan Technical Univercity, Rajasthan</em>
                  </p>
                </div>
                <div className="resume-item">
                  <h4>Bachelor of Computer Science &amp; Engineering</h4>
                  <h5>2009 - 2013</h5>
                  <p>
                    <em>Rajasthan Technical Univercity, Rajasthan</em>
                  </p>
                </div>
              </div>
              <div className="col-lg-6">
                <h3 className="resume-title">Professional Experience</h3>
                <div className="resume-item">
                  <h4>Senior Web Developer</h4>
                  <h5>2017 - Present</h5>
                  <p>
                    <em>Epic Businesses, Jaipur, Rajasthan, India</em>
                  </p>
                  <ul>
                    <li>
                      Lead in the tech team, development, and implementation of
                      the web developer, layout, and production communication
                      materials
                    </li>
                    <li>
                      Delegate tasks to the 7 members of the tech team and
                      provide counsel on all aspects of the project.{" "}
                    </li>
                    <li>
                      Supervise the assessment of all web developer tools in
                      order to ensure quality and accuracy of the design
                    </li>
                  </ul>
                </div>
                <div className="resume-item">
                  <h4>Web Developer</h4>
                  <h5>2015 - 2017</h5>
                  <p>
                    <em>Tax2win, Jaipur, Rajasthan, India</em>
                  </p>
                  <ul>
                    <li>Developed website and project with PHP.</li>
                    <li>
                      Managed up to 2 projects or tasks at a given time while
                      under pressure
                    </li>
                    <li>
                      Recommended and consulted with clients on the most
                      appropriate with Development
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </>
  );
}
