import React from "react";

export default function Footer() {
  return (
    <>
      <footer id="footer">
        <div className="container">
          <div className="">
            <a
              className="btn btn-outline-light btn-floating m-1"
              href="https://www.facebook.com/sahu.manny"
              role="button"
              target="_blank"
              rel="noreferrer"
            >
              <i className="fab fa-facebook-f"></i>
            </a>

            <a
              className="btn btn-outline-light btn-floating m-1"
              href="https://twitter.com/themannysahu"
              role="button"
              target="_blank"
              rel="noreferrer"
            >
              <i className="fab fa-twitter"></i>
            </a>
            <a
              className="btn btn-outline-light btn-floating m-1"
              href="https://www.instagram.com/manny.sahu/"
              role="button"
              target="_blank"
              rel="noreferrer"
            >
              <i className="fab fa-instagram"></i>
            </a>

            <a
              className="btn btn-outline-light btn-floating m-1"
              href="https://in.linkedin.com/in/sahumanny"
              role="button"
              target="_blank"
              rel="noreferrer"
            >
              <i className="fab fa-linkedin-in"></i>
            </a>
          </div>
          <div className="copyright pt-3">
            © 2022 Copyright:
            <a href="https://mannysahu.com/">Name Here</a>
            All Rights Reserved
          </div>
        </div>
      </footer>
    </>
  );
}
