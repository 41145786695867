import React from "react";
import { Link } from "react-router-dom";

const Header = () => {
  return (
    <>
      <header id="header" className="fixed-top">
        <nav className="navbar navbar-expand">
          <div className="container">
            <h1 className="logo me-auto me-lg-0">
              <Link to="/">Manny Sahu</Link>
            </h1>
            <div className="navbar-nav">
              <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                <li className="nav-item">
                  <Link className="nav-link" aria-current="page" to="/">
                    Home
                  </Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link" to="/about">
                    About
                  </Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link" to="/resume">
                    Resume
                  </Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link" to="/services">
                    Services
                  </Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link" to="/portfolio">
                    Portfolio
                  </Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link" to="/contact">
                    Contact
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </nav>
      </header>
      <div className="mobileHeader">
        <div className="container">
          <ul>
            <li>
              <Link to="/">
                <i className="fa-solid fa-house-user"></i>
                <p>Home</p>
              </Link>
            </li>
            <li>
              <Link to="/about">
                <i className="fa-solid fa-address-card"></i>
                <p>About</p>
              </Link>
            </li>
            <li>
              <Link to="/resume">
                <i className="fa-solid fa-file"></i>
                <p>Resume</p>
              </Link>
            </li>
            <li>
              <Link to="/services">
                <i className="fa-solid fa-microchip"></i>
                <p>Services</p>
              </Link>
            </li>
            <li>
              <Link to="/portfolio">
                <i className="fa-solid fa-user-astronaut"></i>
                <p>Portfolio</p>
              </Link>
            </li>
            <li>
              <Link to="/contact">
                <i className="fa-solid fa-file-signature"></i>
                <p>Contact</p>
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </>
  );
};

export default Header;
