import React from "react";

export default function About() {
  return (
    <>
      <main id="main" className="pt-5 mt-5">
        <section id="about" className="about">
          <div className="container" data-aos="fade-up">
            <div className="section-title">
              <h2>About</h2>
              <p>
                Since beginning my journey as a freelance designer nearly 7
                years ago, I’ve done remote work for agencies, consulted for
                startups, and collaborated with talented people to create
                digital products for both business and consumer use. I’m quietly
                confident, naturally curious, and perpetually working on
                improving my chops one design problem at a time.
              </p>
            </div>

            <div className="row">
              <div className="col-lg-4">
                <img
                  src="assets/img/MannySahu.png"
                  className="img-fluid"
                  alt=""
                />
              </div>
              <div className="col-lg-8 pt-4 pt-lg-0 content">
                <h3>PHP, Shopify &amp; React JS Developer</h3>
                <div className="row">
                  <div className="col-lg-6">
                    <ul>
                      <li>
                        <i className="bi bi-rounded-right"></i>{" "}
                        <strong>Birthday:</strong> 09 Jun 1992
                      </li>
                      <li>
                        <i className="bi bi-rounded-right"></i>{" "}
                        <strong>Website:</strong>{" "}
                        <a
                          href="https://mannysahu.com"
                          target="_blank"
                          rel="noreferrer"
                        >
                          mannysahu.com
                        </a>
                      </li>
                      <li>
                        <i className="bi bi-rounded-right"></i>{" "}
                        <strong>Phone:</strong> +91 7737826541, +91 9928413121
                      </li>
                      <li>
                        <i className="bi bi-rounded-right"></i>{" "}
                        <strong>City:</strong> Jaipur, Rajasthan
                      </li>
                    </ul>
                  </div>
                  <div className="col-lg-6">
                    <ul>
                      <li>
                        <i className="bi bi-rounded-right"></i>{" "}
                        <strong>Age:</strong> 28
                      </li>
                      <li>
                        <i className="bi bi-rounded-right"></i>{" "}
                        <strong>Degree:</strong> Master
                      </li>
                      <li>
                        <i className="bi bi-rounded-right"></i>{" "}
                        <strong>Email:</strong> manny.sahu@gmail.com
                      </li>
                      <li>
                        <i className="bi bi-rounded-right"></i>{" "}
                        <strong>Freelance:</strong> Available
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section id="skills" className="skills">
          <div className="container" data-aos="fade-up">
            <div className="section-title">
              <h2>Skills</h2>
            </div>

            <div className="row skills-content">
              <div className="col-lg-6">
                <div className="progress">
                  <span className="skill">
                    HTML <i className="val">98%</i>
                  </span>
                  <div className="progress-bar-wrap htmlSkill">
                    <div className="progress-bar"></div>
                  </div>
                </div>

                <div className="progress">
                  <span className="skill">
                    CSS <i className="val">95%</i>
                  </span>
                  <div className="progress-bar-wrap cssSkill">
                    <div className="progress-bar"></div>
                  </div>
                </div>

                <div className="progress">
                  <span className="skill">
                    JavaScript <i className="val">75%</i>
                  </span>
                  <div className="progress-bar-wrap jsSkill">
                    <div className="progress-bar"></div>
                  </div>
                </div>
                <div className="progress">
                  <span className="skill">
                    React JS <i className="val">40%</i>
                  </span>
                  <div className="progress-bar-wrap rjsSkill">
                    <div className="progress-bar"></div>
                  </div>
                </div>
              </div>

              <div className="col-lg-6">
                <div className="progress">
                  <span className="skill">
                    PHP <i className="val">90%</i>
                  </span>
                  <div className="progress-bar-wrap phpSkill">
                    <div className="progress-bar"></div>
                  </div>
                </div>

                <div className="progress">
                  <span className="skill">
                    WordPress/CMS <i className="val">90%</i>
                  </span>
                  <div className="progress-bar-wrap wpSkill">
                    <div className="progress-bar"></div>
                  </div>
                </div>

                <div className="progress">
                  <span className="skill">
                    Shopify <i className="val">95%</i>
                  </span>
                  <div className="progress-bar-wrap shopifySkill">
                    <div className="progress-bar"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </>
  );
}
